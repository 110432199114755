.toast-error{
  background-color: #f4555a
}

.toast-success{
  background-color: #0bc175
}

.toast-warn{
  background-color: #ffb812
}
